// src/components/Header.js
import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import '../App.css';

const Header = () => {
  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const glow = useSpring({
    textShadow: '0 0 20px rgba(255, 255, 255, 0.8)', /* Modify glow intensity */
    loop: true,
  });

  return (
    <animated.div style={{ ...fadeIn, ...glow }} className="header">
      <h1>Embarking on a Journey of Forever Love</h1>
    </animated.div>
  );
};

export default Header;
