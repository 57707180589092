// src/App.js
import React from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Background from './components/Background';
import Footer from './components/Footer'; // Import the Footer component
import MusicPlayer from './components/MusicPlayer';
import { useSpring, animated } from '@react-spring/web';
import './App.css';

function App() {
  const backgroundAnimation = useSpring({
    opacity: 0.8,
    from: { opacity: 0 },
    config: { duration: 2000 },
  });

  return (
    <div>
      <Background />
      <animated.div style={backgroundAnimation} className="container">
        <Header />
        <MainContent />
        <MusicPlayer />
      </animated.div>
    </div>
  );
}

export default App;
