// src/components/MusicPlayer.js
import React, { useRef } from 'react';
import { useSpring, animated } from '@react-spring/web';
import './MusicPlayer.css'; // Import the CSS file

const MusicPlayer = () => {
  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const audioRef = useRef(null);

  return (
    <animated.div style={fadeIn} className="music-player">
      <audio controls ref={audioRef}>
        <source src={process.env.PUBLIC_URL + '/song.mp3'} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </animated.div>
  );
};

export default MusicPlayer;
