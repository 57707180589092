// src/components/MainContent.js
import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import '../App.css';

const MainContent = () => {
  const fadeInUp = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 800 },
  });

  const glow = useSpring({
    textShadow: '0 0 20px rgba(255, 255, 255, 0.8)', /* Modify glow intensity */
    loop: true,
  });

  return (
    <animated.div style={{ ...fadeInUp, ...glow }} className="main-content">
      <p>
        Join us on this exciting journey! Stay tuned for updates on dates and venues. Your presence will make our celebration complete.
      </p>
    </animated.div>
  );
};

export default MainContent;
