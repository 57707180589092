// src/components/Background.js
import React, { useEffect } from 'react';
import './Background.css';

const Background = () => {
  useEffect(() => {
    const createStaticStar = () => {
      const staticStar = document.createElement('img');
      staticStar.src = process.env.PUBLIC_URL + '/star.png'; // Path to star image
      staticStar.className = 'static-star';
      document.body.appendChild(staticStar);

      const size = Math.random() * 80 + 60; // Random size between 60 and 140 pixels

      // Randomize the starting position within the window boundaries
      const startX = Math.random() * (window.innerWidth - size);
      const startY = Math.random() * (window.innerHeight - size);
      staticStar.style.left = `${startX}px`;
      staticStar.style.top = `${startY}px`;

      staticStar.style.width = `${size}px`;
      staticStar.style.height = `${size}px`;
    };

    // Create 5 static stars
    for (let i = 0; i < 5; i++) {
      createStaticStar();
    }

    const createShootingStar = () => {
      const shootingStar = document.createElement('div');
      shootingStar.className = 'shooting-star';
      document.body.appendChild(shootingStar);
    
      // Randomize the starting position within the window boundaries
      const startX = Math.random() * window.innerWidth;
      const startY = Math.random() * window.innerHeight;
      shootingStar.style.left = `${startX}px`;
      shootingStar.style.top = `${startY}px`;
    
      // Randomize the animation duration
      const duration = Math.random() * 5 + 5; // Random duration between 5 and 10 seconds
      shootingStar.style.animation = `shootingStar ${duration}s linear infinite`;
    
      // Add an event listener for the animation iteration event
      shootingStar.addEventListener('animationiteration', () => {
        const rect = shootingStar.getBoundingClientRect();
    
        // Check if the shooting star is out of the viewport
        if (rect.bottom < 0 || rect.top > window.innerHeight || rect.right < 0 || rect.left > window.innerWidth) {
          // Remove the shooting star element from the HTML body
          document.body.removeChild(shootingStar);
        }
      });
    };
    
    
    

    // Create shooting stars continually falling
    setInterval(createShootingStar, 2000); // Add a new shooting star every 2 seconds
  }, []);

  return (
    <div className="background-container">
      <div className="moon" />
    </div>
  );
};

export default Background;
